import React from "react"

class Navbar extends React.Component{
  render() {
  return (
  <div>
    <nav className="navbar bg-dark navbar-dark navbar-expand-lg" style={{position:"fixed", width:"100%", zIndex:"10"}}>
    <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link"  href="/">Inicio</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/about">Sobre nós</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/servicos">Serviços</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/contactos">Contactos</a>
        </li>
    </ul>
  </div>
</div>
</nav>
</div>
  )
  }
}

export default Navbar