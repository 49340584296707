import React from "react";

class Services extends React.Component{
  render(){
    return(
      <div>
        <div className="container" id="services-main-div">
          <h1>Serviços</h1>
          <div id="services-line-1">
          <div id="services-card-div-1">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="mechanic-img" id="services-img-animated" src="https://images.pexels.com/photos/4315570/pexels-photo-4315570.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                <div className="card-img-overlay" id="services-div-overlay-text">
                  <h1 className="card-text" >Mecânica</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Reparação de Motores; Suspensões; Escapes/Catalizadores; Travões; <br/>Mudanças de Óleo e Filtros;</h5>
                </div>      
            </div>
            </div>
            <div id="services-card-div-2">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="car-body-img" id="services-img-animated" src="https://images.unsplash.com/photo-1594097840961-411cf23dd23f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FyJTIwcGFpbnRpbmd8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"/>
                <div className="card-img-overlay" id="services-div-overlay-text">
                  <h1 className="card-text">Carroçaria</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text">Bate-Chapa; Pintura; <br/>Lubrificação; Aspiração; <br/>Lavagem de Viaturas;</h5>
                </div>
              </div>         
              </div>
              </div>
              <div id="services-line-2">
          <div id="services-card-div-3" >
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="electronic-img" id="services-img-animated" src="https://i.imgur.com/veatJWC.jpg" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Eletrónica</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Ignição; Injeção; <br/> Sistemas de Segurança; <br/> ABS; ESP; ASR;</h5>
                </div>
              </div>      
            </div>
            <div id="services-card-div-4">
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="electricity img" id="services-img-animated" src="https://i.imgur.com/t3t4c69.jpg" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Eletricidade</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text">Ar Condicionado; Alarmes; <br/> Auto-Rádios; Sistemas de Som; <br/>Sistemas de Navegação; Park Pilot; Faróis;</h5>
                </div>
              </div>         
              </div>
              </div>
          <div id="services-card-div-5" >
            
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="aditional services img" id="services-img-animated" src="https://images.unsplash.com/photo-1550564956-8f914edc0477?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Serviços Adicionais</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Alinhamento de Direção;<br/>Equilibragem de Rodas;<br/>Pneus; Estofagem; Pré-I.P.O;</h5>
                </div>
              </div>   
            </div>
          </div>
      </div>
    )
  }
}

export default Services

/*

    return(
      <div>
        <div className="container" id="services-main-div">
          <h1>Serviços</h1>
          <div id="services-card-div-1">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="mechanic-img" id="services-img-animated" src="https://images.pexels.com/photos/4315570/pexels-photo-4315570.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                <div className="card-img-overlay" id="services-div-overlay-text">
                  <h1 className="card-text" >Mecânica</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Reparação de Motores; Suspensões; Escapes/Catalizadores; Travões; <br/>Mudanças de Óleo e Filtros;</h5>
                </div>      
            </div>
            </div>
            <div id="services-card-div-2">
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="car-body-img" id="services-img-animated" src="https://images.unsplash.com/photo-1594097840961-411cf23dd23f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FyJTIwcGFpbnRpbmd8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"/>
                <div className="card-img-overlay" id="services-div-overlay-text">
                  <h1 className="card-text">Carroçaria</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text">Bate-Chapa; Pintura; <br/>Lubrificação; Aspiração; <br/>Lavagem de Viaturas;</h5>
                </div>
              </div>         
              </div>
          
          <div id="services-card-div-3" >
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="electronic-img" id="services-img-animated" src="https://i.imgur.com/veatJWC.jpg" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Eletrónica</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Ignição; Injeção; <br/> Sistemas de Segurança; <br/> ABS; ESP; ASR;</h5>
                </div>
              </div>      
            </div>
            <div id="services-card-div-4">
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="electricity img" id="services-img-animated" src="https://i.imgur.com/t3t4c69.jpg" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Eletricidade</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text">Ar Condicionado; Alarmes; <br/> Auto-Rádios; Sistemas de Som; <br/>Sistemas de Navegação; Park Pilot; Faróis;</h5>
                </div>
              </div>         
              </div>
          
          <div id="services-card-div-5" >
            
              <div class="card" id="services-card-animated">
                <img className="card-img" alt="aditional services img" id="services-img-animated" src="https://images.unsplash.com/photo-1550564956-8f914edc0477?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Serviços Adicionais</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Alinhamento de Direção;<br/>Equilibragem de Rodas;<br/>Pneus; Estofagem; Pré-I.P.O;</h5>
                </div>
              </div>   
            </div>
          </div>
        
        <div id="services-copyright-div">
          <h2>Autoluar, Lda. 2021 <span style={{fontSize:"2vh"}}>©</span></h2>
        </div>
      </div>
    )

        return(
    <div id="services-main-div">
      <h1>Serviços</h1>
      <div>
        <Grid container justify="center">
          <Grid item xs={12} md={6} style={{marginRight: "-10vh"}}>
          <div id="services-cards">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="mechanic-img" id="services-img-animated" src="https://images.pexels.com/photos/4315570/pexels-photo-4315570.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                <div className="card-img-overlay" id="services-div-overlay-text">
                  <h1 className="card-text" >Mecânica</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Reparação de Motores; Suspensões; Escapes/Catalizadores; Travões; <br/>Mudanças de Óleo e Filtros;</h5>
                </div>      
            </div>
          </div>
          </Grid>
          <Grid item xs={12} md={6}>
          <div id="services-cards">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="car-body-img" id="services-img-animated" src="https://images.unsplash.com/photo-1594097840961-411cf23dd23f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FyJTIwcGFpbnRpbmd8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"/>
                <div className="card-img-overlay" id="services-div-overlay-text">
                  <h1 className="card-text">Carroçaria</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text">Bate-Chapa; Pintura; <br/>Lubrificação; Aspiração; <br/>Lavagem de Viaturas;</h5>
                </div>
              </div>         
              </div>
          </Grid>
          <Grid item xs={12} md={6}>
          <div id="services-cards">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="electronic-img" id="services-img-animated" src="https://i.imgur.com/veatJWC.jpg" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Eletrónica</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text" >Ignição; Injeção; <br/> Sistemas de Segurança; <br/> ABS; ESP; ASR;</h5>
                </div>
              </div>      
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
          <div id="services-cards">
              <div className="card" id="services-card-animated">
                <img className="card-img" alt="electricity img" id="services-img-animated" src="https://i.imgur.com/t3t4c69.jpg" />
                <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                  <h1 className="card-text">Eletricidade</h1>
                </div>
                <div className="card-img-overlay" id="services-div-background-text">
                  <h5 className="card-text">Ar Condicionado; Alarmes; <br/> Auto-Rádios; Sistemas de Som; <br/>Sistemas de Navegação; Park Pilot; Faróis;</h5>
                </div>
              </div>         
              </div>
          </Grid>
          <Grid item xs={12} md={6}>       
          <div id="services-cards">
            <div className="card" id="services-card-animated">
              <img className="card-img" alt="aditional services img" id="services-img-animated" src="https://images.unsplash.com/photo-1550564956-8f914edc0477?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80" />
              <div className="card-img-overlay" id="services-div-overlay-text" style={{opacity:"0.9"}}>
                <h1 className="card-text">Serviços Adicionais</h1>
              </div>
              <div className="card-img-overlay" id="services-div-background-text">
                <h5 className="card-text" >Alinhamento de Direção;<br/>Equilibragem de Rodas;<br/>Pneus; Estofagem; Pré-I.P.O;</h5>
              </div>
            </div>   
          </div>
          </Grid>
        </Grid>
      </div>
      </div>
    )

    */