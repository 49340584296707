import React from "react"
import { Router, Route, Switch } from "react-router-dom";

import history from "./history";
import Navbar from "./components/Navbar"
import MainPage from "./pages/MainPage"
import About from "./pages/About"
import Contacts from "./pages/Contacts"
import Services from "./pages/Services"
import Privacy from "./pages/Privacy";
import Footer from "./components/Footer";



function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Navbar />
        <img id="back-logo" alt="back-img" src="https://i.imgur.com/K8JSoFs.png"/>
        <Switch>
          <Route path="/about" exact component={About}/>
          <Route path="/contactos" exact component={Contacts} />
          <Route path="/servicos" exact component={Services} />
          <Route path="/politica-privacidade" exact component={Privacy} />
          <Route path="/" component={MainPage}/>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
