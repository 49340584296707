import React from "react";
import PrivacyModal from "../components/PrivacyModal";

class MainPage extends React.Component{


  render(){
    return (
      <div>
         <PrivacyModal />
        <div style={{textAlign:"center"}}>
          <img id="main-logo" src="./autoluar_logo_final.png" alt="Autoluar logo"/>
          <h1 id="main-text">REPARAÇÃO <br/> AUTOMÓVEL</h1>
          {/*<h4 id="secondary-text">Multimarcas especializada em Volvo</h4>*/}
        </div>
      </div>
    )
  }
}

export default MainPage