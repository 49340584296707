import React from "react";
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    zIndex: "1000",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  content: {
    backgroundColor: "#1a1a1a",
    borderColor: "black"
  }
};

function PrivacyModal(){
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Privacy Modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={customStyles}
        id="privacy-modal"
      >
        <h4>Politica de Privacidade</h4>
        <div className="row">
          <div className="col-12 col-xl-10">
           <p>Ao continuar a navegação está a consentir a utilização dos seus dados neste website, para os efeitos mencionados na <a href="/politica-privacidade">Politica de Privacidade</a>.</p>
          </div> 
          <div className="col-12 col-xl-3">
          <button className="btn btn-secondary" onClick={closeModal}>Aceito</button>
          </div> 
       
        
        </div>
      </Modal>
    </div>
  );
}

export default PrivacyModal

/*
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button>



      <div className="modal" tabindex="-1" role="dialog" style={{display: "initial"}}>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Politica de Privacidade</h5>
      </div>
      <div className="modal-body">
        <p>Ao continuar a navegação está a consentir a utilização dos seus dados neste website.</p>
        <p>Ver <a href="/politica-privacidade">Politica de Privacidade</a></p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Aceito</button>
      </div>
    </div>
  </div>
</div>


    top: '0',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',

*/