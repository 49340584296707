import React from "react";
import emailjs from "emailjs-com";


const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      buttonSubmit: null,
      firstName: null,
      email: null,
      phone: null,
      message: null,
      errors: {
        firstName: " ",
        email: " ",
        phone: " ",
        message: " "
      },
      confirmationMessage: null
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('gmail', 'autoluar_template', e.target, "user_dm9mkfv2VCp2nPXXg4z0v")
    if(validateForm(this.state.errors)) {
      console.info("Valid Form")
      this.setState({ confirmationMessage: "Mensagem enviada com sucesso!", isDisabled:true})
    }else{
      console.error("Invalid Form")
      this.setState({ confirmationMessage: "Não foi possivel enviar a sua mensagem. Por favor tente mais tarde."})
    }
    e.target.reset();
  }


  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.firstName = 
        value.length < 3 
        ? "Introduza o seu nome." 
        : "";
        break;
      case "email": 
        errors.email = 
          validEmailRegex.test(value)
            ? "" 
            : "Introduza um endereço de email válido.";
        break;
      case "phone": 
        errors.phone = 
          value.length < 9
            ? "Introduza um número de telefone válido."
            : "";
        break;
      case "message" :
        errors.message =
        value.length < 10
            ? "Introduza a sua mensagem. Deverá conter no minimo 10 caracteres"
            : "" ;
      break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });

    if (
      errors.email.length === 0 &&
      errors.phone.length === 0 &&
      errors.message.length === 0 &&
      errors.firstName.length === 0
    ) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  
  render() {
    const {errors} = this.state;
    let confirmationMessage = this.state.confirmationMessage
  return (
    <div>
     <div className="container fluid">
       <form onSubmit={this.handleSubmit}>
        <div className="row pt-4">
          <div className="col-xl-9 col-12 form-group mx-auto" id="form-row">
            <input type="text" className="form-control" placeholder="Nome" name="name" onChange={this.handleChange}/>
            {errors.firstName.length > 0 && 
                <span className='error'>{errors.firstName}</span>}
          </div>
          <div className="col-xl-9 col-12 form-group pt-2 mx-auto" id="form-row">
            <input type="email" className="form-control" placeholder="Endereço de email" name="email" onChange={this.handleChange} noValidate/>
            {errors.email.length > 0 && 
                <span className='error'>{errors.email}</span>}
          </div>
          <div className="col-xl-9 col-12 form-group pt-2 mx-auto" id="form-row">
            <input type="tel" className="form-control" placeholder="Contacto telefónico" name="phone" onChange={this.handleChange} noValidate/>
            {errors.phone.length > 0 &&
              <span className="error">{errors.phone}</span>}
          </div>
          <div className="col-xl-9 col-12 form-group pt-2 mx-auto" id="form-row">
            <textarea className="form-control" id="" cols="30" rows="6" placeholder="Mensagem" name="message" onChange={this.handleChange} noValidate></textarea>
              {errors.message.length > 0 &&
              <span className="error">{errors.message}</span>}
          </div>
          <div className="col-xl-8 pt-3 mx-auto">
            <button className="btn btn-dark" disabled={this.state.isDisabled}>Submeter</button>
            <span className="alert alert success" role="alert">{confirmationMessage}</span>
          </div>
        </div>
       </form>
     </div>
   </div>
)
}
}


