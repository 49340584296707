import React from "react";

import Form from "../components/Form";

class Contacts extends React.Component{

  render(){
    return (
			<div>
				<div className="container" id="contacts-main-div">
					<div className="row mx-auto mt-5">
						<div className="col-xl-8 mx-auto" style={{ marginTop: "-5vh" }}>
							<h1>Entre em contacto!</h1>
						</div>
					</div>
					<div className="row pt-2 mx-auto">
						<div className="col-xl-8 mx-auto">
							<h5>Respondemos em menos de 24 horas</h5>
						</div>
					</div>
					<div>
						<Form />
					</div>
					<div className="row mx-auto" style={{ paddingTop: "3vh" }}>
						<div className="col-lg-8" id="contacts-details-div">
							<h5>
								AV. MARIA LUÍSA BRAANCAMP, 7 <br /> 2685-080 SACAVÉM
							</h5>
							<br />
							<br />

							<h5>
								<i className="fas fa-phone" style={{ paddingTop: "6vh" }}></i> +351 211 929 855 (chamada para rede fixa nacional)
							</h5>
							<h5>
								<i className="fas fa-mobile-alt" style={{ paddingRight: "7px", lineHeight: "4vh" }}></i> +351 913 786 914 (chamada para rede
								móvel nacional)
							</h5>
							<h5>
								<i className="fas fa-mobile-alt" style={{ paddingRight: "7px" }}></i> +351 937 693 826 (chamada para rede
								móvel nacional)
							</h5>
							<h5>
								<i className="fas fa-envelope"></i>{" "}
								<a href="mailto:autoluar@autoluar.com" target="_blank" style={{ textDecoration: "none", color: "white" }} rel="noreferrer">
									autoluar@autoluar.pt
								</a>
							</h5>
						</div>
						<div className="col-lg-6 mx-lg-auto">
							<iframe
								id="contact-maps"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.744638317867!2d-9.108108684327451!3d38.79248806072574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1932039335491d%3A0x774deb09705aa22c!2zQXV0b0x1YXIgLSBDb23DqXJjaW8gZSBSZXBhcmHDp8OjbyBBdXRvbcOzdmVsLCBMZGEu!5e0!3m2!1spt-PT!2spt!4v1619450911625!5m2!1spt-PT!2spt"
								allowfullscreen=""
								loading="lazy"
							></iframe>
						</div>
					</div>
				</div>
			</div>
		);
  }
}

export default Contacts