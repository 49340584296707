import React from "react"

class About extends React.Component{
  render(){
    return(
      <div>
        <div className="container" id="about-main-div">
          <div className="row mx-auto">
            <div className="col-xl-6">
              <h5>A AUTOLUAR é uma oficina especializada em vários serviços mecânicos onde o seu veículo é tratado com todo o carinho e respeito por técnicos altamente especializados nas diversas áreas de actuação.</h5>
            </div>
          </div>
          <div className="row pt-5 mx-auto">
            <div className="col-xl-6">
              <h5>A nossa missão é oferecer serviços de mecânica preventiva, utilizando tecnologia de precisão e profissionais com conhecimento técnico e humano. </h5>
              <h5>Para tal, contamos com uma unidade bem estruturada para melhor atender os nossos clientes, e profissionais altamente treinados e qualificados que garantem a qualidade dos nossos serviços.</h5>
            </div>
          </div>
          <div className="row pt-5 mx-auto">
            <div className="col-xl-6">
              <h5>Venha conhecer-nos!</h5>
              <button id="about-btn" class="btn btn-secondary"><a href="/servicos" id="about-a">Serviços</a></button>
              <button type="button" id="about-btn" class="btn btn-secondary" style={{marginLeft:"4vh"}}><a href="contactos" id="about-a2">Contactos</a></button>
            </div>
          </div>
          <div>
            <img id="about-img" alt="front-img" src="https://i.imgur.com/Txn1lK8.jpg"/>
          </div>
        </div>
      </div>
    )
  }
}

export default About