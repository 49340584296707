import React from "react"

class Privacy extends React.Component{
  render(){
    return(
      <div id="privacy-main-div" className="container">
        <h1 style={{marginBottom: "5vh"}}>Politica de Privacidade</h1>
        <div id="privacy-list">
          <ol>
            <li>A Autoluar, LDA (Avenida Maria Luísa Braancamp 7, 2685-080 Sacavém) é responsável pelo tratamento dos dados pessoais que lhe sejam cedidos pelo respetivo titular.</li>
            <li>O tratamento dos dados pessoais fundamenta-se no consentimento dado pelos respetivos titulares na presente declaração e destina-se à melhoria e adequação do serviço bem como à elaboração de campanhas de Marketing e Publicidade.</li>
            <li>O titular dos dados pode retirar o seu consentimento a qualquer altura, devendo utilizar o seguinte endereço de e-mail para o efeito: autoluar@autoluar.pt.</li>
            <li>Os dados pessoais recolhidos junto dos seus titulares pela Autoluar, LDA são disponibilizados aos respetivos colaboradores devidamente credenciados, assegurando-se a integralidade e confidencialidade dos dados pessoais.</li>
            <li>Os dados pessoais objeto de tratamento serão conservados pelo período máximo de 10 anos.</li>
            <li>O Titular dos dados tem, nos termos gerais, o direito de solicitar ao responsável pelo tratamento o acesso, a retificação, a portabilidade ou o apagamento dos seus dados pessoais bem como a limitação ou oposição ao tratamento.</li>
            <li>O titular dos dados tem ainda o direito de apresentar reclamações junto da autoridade de controlo.</li>
            <li>O titular dos dados não se encontra legal nem contratualmente obrigado a fornecer os seus dados pessoais ao responsável pelo tratamento, inexistindo qualquer consequência para a recusa da sua cedência.</li>
          </ol>
        </div>
        <h5>Tendo tomado conhecimento das informações supra, as quais foram cedidas voluntaria e gratuitamente pela Autoluar, LDA, declaro que consinto que o mesmo proceda a operações de tratamento dos meus dados pessoais para as finalidades acima elencadas.</h5>
      </div>
    )
  }
}

export default Privacy