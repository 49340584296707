import React from "react"

class Footer extends React.Component{
  render() {
    return(
      <div className="container-fluid"  >
        <div className="row" id="footer-div" style={{borderTop: "1px solid #4a4a4a"}} >
          <div id="footer-nav-div" className="col-xl-1 col-4">
            <p ><a style={{color: "#c7c8c9"}} href="/">Inicio</a></p>
            <p><a style={{color: "#c7c8c9"}} href="/about">Sobre nós</a></p> 
          </div>
          <div id="footer-nav-div" className="col-xl-1 col-4">
          <p><a style={{color: "#c7c8c9"}} href="/contactos">Onde estamos</a></p>
            <p><a style={{color: "#c7c8c9"}} href="/contactos">Contactos</a></p>
          </div>
          <div id="footer-nav-div" className="col-xl-2 col-4">
          <p><a style={{color: "#c7c8c9"}} href="/servicos">Serviços</a></p>
            <p><a style={{color: "#c7c8c9"}} href="/politica-privacidade">Politica de Privacidade</a></p>
          </div>
          <div className="col-xl-6 col-12">
            <div id="copyright-div-2">
            <h2>Autoluar, Lda. {new Date().getFullYear()} <span style={{fontSize:"2vh"}}>©</span> - Multimarcas especializada em Volvo</h2>
            </div>
          </div>
          <div className="col-xl-2 col-12" style={{textAlign:"center"}}>
          <a style={{color: "#c7c8c9"}} href="https://www.livroreclamacoes.pt/inicio">
            <img id="livro-reclamacoes" alt="livro-reclamações" src="https://i.imgur.com/09mK1gP.png"/></a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer

/*

    return (
      <div style={{textAlign:"center", marginTop: "5vh"}}>
        <div>
          <div style={{display:"inline-block", textAlign:"left", marginLeft: "-20vh"}}>
            <p ><a style={{color: "#c7c8c9"}} href="/">Inicio</a></p>
            <p><a style={{color: "#c7c8c9"}} href="/about">Sobre nós</a></p>    
          </div>
          <div style={{display:"inline-block", textAlign:"left", marginLeft: "2vh"}}>
            <p><a style={{color: "#c7c8c9"}} href="/contactos">Onde estamos</a></p>
            <p><a style={{color: "#c7c8c9"}} href="/contactos">Contactos</a></p>
          </div>
          <div style={{display:"inline-block", textAlign:"left", marginLeft: "2vh"}}>
            <p><a style={{color: "#c7c8c9"}} href="/servicos">Serviços</a></p>
            <p><a style={{color: "#c7c8c9"}} href="/politica-privacidade">Politica de Privacidade</a></p>
          </div>
          <div id="copyright-div">
          <h2>Autoluar, Lda. {new Date().getFullYear()} <span style={{fontSize:"2vh"}}>©</span></h2>
          </div>
          <div style={{display: "inline-block"}}>
            <a style={{color: "#c7c8c9"}} href="https://www.livroreclamacoes.pt/inicio">
            <img id="livro-reclamacoes" alt="livro-reclamações" src="https://i.imgur.com/09mK1gP.png"/></a>
          </div>
        </div>
      </div>
    )

    */